import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import PropTypes from 'prop-types';
import React from 'react';
import DropdownMenu from '@shoreag/base/DropdownMenu';
import { Query } from 'react-apollo';
import { Form } from 'react-final-form';
import { get, noop } from 'lodash';
import Spinner from '@shoreag/base/Spinner';
import allClientQuery from '../../graphql/queries/all-client.gql';
import ToolbarButton from '../ToolbarButton';
import DotsIcon from '../DotsIcon';
import Route from '../Route';
import renderDropdownMenuItem from '../../utilities/render-dropdown-menu-item';
import dashboardConstants from '../../utilities/dashboard-constants';
import setDashboardURL from '../../utilities/set-dashboard-url';
import DashboardPaginator from '../DashboardPaginator';
import ClientSearchFilterForm from './ClientSearchFilterForm';
import ColumnsGridCard from '../ColumnsGridCard';
import DisplayPartnerData from '../DisplayPartnerData';

const ClientDashboardPage = ({ location, navigate }) => {
  const {
    filters,
    sortCriteria,
    pageCriteria,
  } = dashboardConstants.getCriterias({
    search: location.search,
    type: 'client',
  });

  const { values } = filters;

  return (
    <Route
      header={{
        rightContainer: (
          <ToolbarButton icon="plus" label="Create New" link="/client/create" />
        ),
        title: 'Client Management',
      }}
      isPrivate
    >
      <ClientSearchFilterForm
        filters={filters}
        page={pageCriteria}
        sort={sortCriteria}
      />
      <Form
        initialValues={{
          name: values.name || '',
          partnerId: values.partnerId || '',
        }}
        onSubmit={noop}
        render={(formContext) => {
          const { values, handleSubmit } = formContext;
          return (
            <Box
              as="form"
              onSubmit={handleSubmit}
              sx={{
                position: 'relative',
              }}
            >
              <Query
                fetchPolicy="network-only"
                notifyOnNetworkStatusChange
                query={allClientQuery}
                variables={{
                  searchBody: JSON.stringify({
                    limit: pageCriteria.pageSize,
                    name: values.name,
                    offset: pageCriteria.offset,
                    partnerId: values.partnerId,
                  }),
                }}
              >
                {({ data, loading }) => {
                  const clientList = get(data, 'allClient.clients', []);
                  return loading ? (
                    <Spinner py="md" />
                  ) : (
                    <>
                      {clientList.map((client) => {
                        const { admin } = client;
                        const { features } = client;
                        const { partners } = client;
                        return (
                          <Card
                            key={client.id}
                            display="flex"
                            sx={{
                              borderRadius: 1,
                              minHeight: '140px',
                              px: 0,
                              py: 6,
                            }}
                          >
                            <Box
                              sx={{
                                position: 'absolute',
                                right: '15px',
                                top: '15px',
                                transform: 'rotate(90deg)',
                              }}
                            >
                              <DropdownMenu
                                button={<DotsIcon />}
                                itemActions={[`/client/${client.id}`]}
                                items={[['view', 'View']].map(
                                  renderDropdownMenuItem
                                )}
                              />
                            </Box>
                            <Box
                              sx={{
                                borderRight: '1px solid',
                                borderRightColor: 'grays.1',
                                px: 6,
                                width: '25%',
                              }}
                            >
                              <Box
                                as="h2"
                                fontSize={3}
                                mb={5}
                                onClick={() => navigate(`/client/${client.id}`)}
                                sx={{
                                  cursor: 'pointer',
                                }}
                              >
                                {client.clientName}
                              </Box>
                              <Box
                                alignItems="flex-start"
                                display="flex"
                                mb={4}
                              >
                                <Box color="grays.4" fontSize={2} width="80px">
                                  Admin:
                                </Box>
                                {admin.length > 0
                                  ? admin.map((item, i) => (
                                      <Box
                                        key={`${item}-${i}`}
                                        sx={{
                                          fontSize: 2,
                                          py: 2,
                                          wordWrap: 'break-word',
                                        }}
                                      >
                                        {item}
                                      </Box>
                                    ))
                                  : '-NA-'}
                              </Box>
                              <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Box alignItems="center" display="flex">
                                  <Box
                                    color="grays.4"
                                    fontSize={2}
                                    width="80px"
                                  >
                                    Users:
                                  </Box>
                                  <Box fontSize={2}>{client.usersCount}</Box>
                                </Box>
                                <Box color="grays.4" fontSize={2}>
                                  (Capacity: {client.capacity})
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                borderRight: '1px solid',
                                borderRightColor: 'grays.1',
                                px: 6,
                                width: '35%',
                              }}
                            >
                              <ColumnsGridCard label="Partners:">
                                {partners?.length > 0
                                  ? partners.map((partner, i) => {
                                      return (
                                        <Box key={i} fontSize={2}>
                                          <DisplayPartnerData
                                            partnerId={partner.id}
                                          />
                                        </Box>
                                      );
                                    })
                                  : '-NA-'}
                              </ColumnsGridCard>
                            </Box>
                            <Box
                              sx={{
                                px: 6,
                                width: '40%',
                              }}
                            >
                              <ColumnsGridCard label="Features:">
                                {features?.length > 0
                                  ? features.map(({ featuresName }, i) => {
                                      return (
                                        <Box
                                          key={`${featuresName}-${i}`}
                                          fontSize={2}
                                          sx={{ fontWeight: '600', py: 2 }}
                                        >
                                          {featuresName}
                                        </Box>
                                      );
                                    })
                                  : '-NA-'}
                              </ColumnsGridCard>
                            </Box>
                          </Card>
                        );
                      })}
                      <DashboardPaginator
                        filters={{
                          page: pageCriteria.offset,
                        }}
                        goToNextPage={() =>
                          navigate(
                            setDashboardURL({
                              filters,
                              filterValues: filters.values,
                              page: {
                                offset:
                                  pageCriteria.offset + pageCriteria.pageSize,
                                pageSize: pageCriteria.pageSize,
                              },
                              pathname: location.pathname,
                              sort: sortCriteria,
                            }),
                            { replace: true }
                          )
                        }
                        goToPreviousPage={() =>
                          navigate(
                            setDashboardURL({
                              filters,
                              filterValues: filters.values,
                              page: {
                                offset:
                                  pageCriteria.offset - pageCriteria.pageSize,
                                pageSize: pageCriteria.pageSize,
                              },
                              pathname: location.pathname,
                              sort: sortCriteria,
                            }),
                            { replace: true }
                          )
                        }
                        isClientSidePagination
                        pageTotal={clientList.length}
                      />
                    </>
                  );
                }}
              </Query>
            </Box>
          );
        }}
      />
    </Route>
  );
};

ClientDashboardPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
};

export default ClientDashboardPage;
