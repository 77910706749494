import PropTypes from 'prop-types';
import React, { useContext, useMemo, useCallback } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { composeValidations, email, required } from '@shoreag/validations';
import { FORM_ERROR } from 'final-form';
import get from 'lodash/get';
import { AuthContext } from '@shoreag/auth';
import Spinner from '@shoreag/base/Spinner';
import Checkbox from '@shoreag/base/Checkbox';
import Box from '@shoreag/base/Box';
import Button from '@shoreag/base/Button';
import Input from '@shoreag/base/Input';
import Card from '@shoreag/base/Card';
import useSnackbar from '../../utilities/use-snackbar';
import Route from '../Route';
import FormError from '../FormError';
import NotFoundPage from '../../routes/default/404';
import AllClientGroupDropdown from '../AllClientGroupDropdown';
import ButtonGroups from '../ButtonGroups';
import updateClientUser from '../../graphql/mutations/update-client-user.gql';
import clientUserQuery from '../../graphql/queries/client-user.gql';

const ClientUserEditPage = ({ clientId, userId, navigate }) => {
  const { user: authUser } = useContext(AuthContext);
  const [setSnack] = useSnackbar();

  if (!authUser.isAdmin) return <NotFoundPage />;

  const { data, loading } = useQuery(clientUserQuery, {
    fetchPolicy: 'network-only',
    variables: { id: userId || '' },
  });

  const [updateClientUserMutation] = useMutation(updateClientUser);

  const clientUser = useMemo(() => get(data, 'clientUser', {}), [data]);

  const initialValues = useMemo(
    () => ({
      ...clientUser,
      groups: get(clientUser, 'groups', []).map((group) => group.id),
    }),
    [clientUser]
  );

  const handleSubmit = useCallback(
    async (values) => {
      const payload = {
        variables: {
          email: values.email || '',
          firstName: values.firstName || '',
          groups: values.isAdmin ? [] : values.groups || [],
          id: userId,
          lastName: values.lastName || '',
        },
      };

      try {
        const { data } = await updateClientUserMutation(payload);
        if (data?.updateClientUser?.email) {
          setSnack(
            <Box sx={{ textAlign: 'center' }}>
              User: <b>{data.updateClientUser.email}</b> is updated{' '}
              <Box as="span" sx={{ color: 'success' }}>
                successfully.
              </Box>
            </Box>
          );
          navigate(`/client/${clientId}`);
        }
      } catch (e) {
        return { [FORM_ERROR]: get(e, 'graphQLErrors[0].message', e.message) };
      }
    },
    [updateClientUserMutation, userId, setSnack, navigate, clientId]
  );

  if (loading) return <Spinner />;

  return (
    <Route
      header={{
        icon: 'users',
        title: 'Create User',
        type: 'User',
      }}
      isPrivate
    >
      <FinalForm
        initialValues={initialValues}
        mutators={arrayMutators}
        onSubmit={handleSubmit}
        render={({ handleSubmit, submitting, values, submitError }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Card maxWidth="maxWidths.largeForm" mb={5} mx="auto" pb={5}>
                <Box as="h2" sx={{ fontSize: 5, mb: 6, mt: 0 }}>
                  Details
                </Box>
                <Field
                  component={Input}
                  label="Enter Email"
                  name="email"
                  validate={composeValidations(required, email)}
                />
                <Field
                  component={Input}
                  label="Enter FirstName"
                  name="firstName"
                  wrapperSx={{
                    mt: 5,
                  }}
                />
                <Field
                  component={Input}
                  label="Enter LastName"
                  name="lastName"
                  wrapperSx={{
                    mt: 5,
                  }}
                />
                <Field
                  component={Checkbox}
                  label="This user is an admin"
                  name="isAdmin"
                  type="checkbox"
                  wrapperSx={{
                    alignItems: 'center',
                    display: 'flex',
                    mb: 5,
                    mt: 5,
                  }}
                />
                <AllClientGroupDropdown
                  clientId={clientId}
                  data-cy="groups"
                  disabled={values.isAdmin}
                  isClearable
                  isMulti
                  isRequired={false}
                  name="groups"
                  showLabel
                  wrapperSx={{
                    mb: 5,
                    pb: 5,
                  }}
                />
              </Card>
              <FormError>{submitError}</FormError>
              <ButtonGroups sx={{ justifyContent: 'center', mt: 6 }}>
                <Button link={`/client/${clientId}`} variant="buttons.cancel">
                  Cancel
                </Button>

                <Button
                  submitting={submitting}
                  type="submit"
                  width="submitButton"
                >
                  Update User
                </Button>
              </ButtonGroups>
            </form>
          );
        }}
      />
    </Route>
  );
};

ClientUserEditPage.propTypes = {
  clientId: PropTypes.string,
  navigate: PropTypes.func.isRequired,
  userId: PropTypes.string,
};

ClientUserEditPage.defaultProps = {
  clientId: null,
  userId: null,
};

export default ClientUserEditPage;
