import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import get from 'lodash/get';
import { AuthContext } from '@shoreag/auth';
import { Field, Form as FinalForm } from 'react-final-form';
import { ThemeContext } from 'styled-components';
// import { composeValidations, email, required } from '@shoreag/validations';
import Box from '@shoreag/base/Box';
import Modal from '@shoreag/base/Modal';
import Input from '@shoreag/base/Input';
import Button from '@shoreag/base/Button';
import { FORM_ERROR } from 'final-form';
import Dropdown from '@shoreag/base/Dropdown';
import Card from '@shoreag/base/Card';
import arrayMutators from 'final-form-arrays';
import { Mutation } from 'react-apollo';
// import AddButton from '../AddButton';
import createClientGroup from '../../graphql/mutations/create-client-group.gql';
import useSnackbar from '../../utilities/use-snackbar';
import { DEFAULT_GRID_GAP } from '../../utilities/constants';
import AllPartnersDropdown from '../AllPartnersDropdown';
import AllClientUserDropdown from '../AllClientUserDropdown';
import PillsGroup from '../PillsGroup';
import NotFoundPage from '../../routes/default/404';
import Route from '../Route';
import RoleFormSection from '../RoleFormSection';
import FormError from '../FormError';
import CheckUserExists from '../CheckUserExists';
import AddButton from '../AddButton';
import RemoveButton from '../RemoveButton';
import ButtonGroups from '../ButtonGroups';

const allRoleDropdown = [
  {
    id: 'shore:dex:role::41ebd714-a308-440d-b7bf-590d9d24e2a4',
    roleName: 'Text 1',
  },
  {
    id: 'shore:dex:role::34894d56-f228-4eb1-b67d-3188c5718636',
    roleName: 'Admin',
  },
  {
    id: 'shore:dex:role::b8edb391-267b-45c5-b99d-6b954c2f5b1d',
    roleName: 'Trust 1 Update',
  },
];
const ClientGroupCreatePage = ({ clientId, navigate }) => {
  const {
    defaultStyles: { twoColumnGrid },
  } = useContext(ThemeContext);
  const { user: authUser } = useContext(AuthContext);
  if (!authUser.isAdmin) return <NotFoundPage />;

  const [showModal, setShowModal] = useState(false);
  const [isInviteBoxVisible, setIsInviteBoxVisible] = useState(false);
  const [formValues] = useState({
    groupName: '',
    partners: [],
    roleId: '',
    users: [],
  });

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const [setSnack] = useSnackbar();
  // const newRoleButton = (
  //   <Box
  //     onClick={toggleModal}
  //     sx={{
  //       color: 'accent',
  //       cursor: 'pointer',
  //     }}
  //   >
  //     Create new role
  //   </Box>
  // );

  const gridCardStyle = {
    minHeight: '100%',
    mt: 0,
  };

  return (
    <Route
      header={{
        icon: 'users',
        title: 'Create Group',
        type: 'User',
      }}
      isPrivate
    >
      <Mutation mutation={createClientGroup}>
        {(createClientGroup) => (
          <FinalForm
            initialValues={formValues}
            mutators={arrayMutators}
            onSubmit={async (values) => {
              const payload = {
                variables: {
                  clientId,
                  groupName: values?.groupName ? values.groupName : '',
                  partners: (values?.partners || []).map(
                    (partner) => partner.id
                  ),
                  roleId: values?.roleId ? values.roleId : '',
                  users: (values?.users || []).map((user) => user.email),
                },
              };

              try {
                const {
                  data: {
                    createClientGroup: { groupName },
                  },
                } = await createClientGroup(payload);

                if (groupName) {
                  setSnack(
                    <Box sx={{ textAlign: 'center' }}>
                      UserGroup:
                      <b> {groupName}</b>
                      is created&nbsp;
                      <Box as="span" sx={{ color: 'success' }}>
                        successfully.
                      </Box>
                    </Box>
                  );
                }

                navigate(`/client/${clientId}`);
              } catch (e) {
                return {
                  [FORM_ERROR]: get(e, 'graphQLErrors[0].message', e.message),
                };
              }
            }}
            render={(formContext) => {
              const users = formContext?.values?.users || [];
              return (
                <form onSubmit={formContext.handleSubmit}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      m: `-${DEFAULT_GRID_GAP / 2}px`,
                    }}
                  >
                    <Box sx={twoColumnGrid}>
                      <Card sx={gridCardStyle}>
                        <Box as="h2" sx={{ fontSize: 5, mb: 6, mt: 0 }}>
                          Details
                        </Box>
                        <Field
                          component={Input}
                          label="Enter Group Name"
                          name="groupName"
                          wrapperSx={{ mb: 5 }}
                        />
                        <Field
                          component={Dropdown}
                          label="Role"
                          name="roleId"
                          options={allRoleDropdown.map(({ id, roleName }) => ({
                            label: roleName || '-',
                            value: id,
                          }))}
                        />
                        <Box
                          sx={{
                            color: 'accentSecondary',
                            fontSize: 2,
                            mb: 5,
                            mt: 1,
                          }}
                        >
                          This role will be assigned to all the users belonging
                          to this group
                        </Box>
                        <AllPartnersDropdown
                          isMulti
                          isRequired={false}
                          name="partners"
                          showLabel
                          wrapperSx={{
                            mt: 3,
                          }}
                        />

                        <PillsGroup
                          data={get(formContext.values, `partners`, []).map(
                            (partner) => ({
                              label: partner.partnerName,
                            })
                          )}
                          onRemove={(parameter) =>
                            formContext.form.change(
                              `partners`,
                              get(formContext.values, `partners`, []).filter(
                                (p) => p.partnerName !== parameter.label
                              )
                            )
                          }
                          pillSx={{
                            '& a': { textDecoration: 'none' },
                            '& div': { bg: 'accent', color: 'white' },
                            '&:hover': { bg: 'accent' },
                            bg: 'accent',
                            borderRadius: '1rem',
                          }}
                          wrapperSx={{
                            pt: 4,
                          }}
                        />
                      </Card>
                    </Box>
                    <Box sx={twoColumnGrid}>
                      <Card sx={gridCardStyle}>
                        <Box as="h2" sx={{ fontSize: 4, mb: 2, mt: 0 }}>
                          Invite user(s)
                        </Box>
                        <Box
                          sx={{
                            color: 'grays.4',
                            fontSize: 2,
                            mt: 1,
                          }}
                        >
                          This user(s) is the admin. The admin will have all the
                          permissions of this client.
                        </Box>
                        <AllClientUserDropdown
                          clientId={clientId}
                          isMulti
                          isRequired={false}
                          name="users"
                          showLabel={false}
                        />
                        {isInviteBoxVisible && (
                          <Box
                            mt={5}
                            sx={{
                              alignItems: 'flex-end',
                              display: 'flex',
                              gap: '10px',
                            }}
                          >
                            <Field
                              component={Input}
                              label="Invite New User"
                              name="newUser"
                              placeholder="Enter Email Id"
                            />
                            <Button
                              onClick={() => {
                                const {
                                  newUser,
                                } = formContext.form.getState().values;
                                if (newUser) {
                                  formContext.form.change('users', [
                                    ...formContext.form.getState().values.users,
                                    newUser,
                                  ]);
                                  formContext.form.change('newUser', ''); // Clear the input field after adding the user
                                }
                              }}
                            >
                              Invite
                            </Button>
                          </Box>
                        )}
                        {isInviteBoxVisible ? (
                          <RemoveButton
                            onClick={() => setIsInviteBoxVisible(false)}
                            sx={{
                              color: 'accent',
                              float: 'right',
                              marginTop: 2,
                              mx: 0,
                            }}
                          >
                            Remove
                          </RemoveButton>
                        ) : (
                          <AddButton
                            label="Add user"
                            labelSx={{
                              color: 'accent',
                            }}
                            onClick={() => setIsInviteBoxVisible(true)}
                            sx={{
                              '&:hover': { bg: 'accentLight' },
                              alignItems: 'center',
                              bg: 'accent',
                              display: 'flex',
                              fontSize: 4,
                              height: '16px',
                              justifyContent: 'center',
                              width: '16px',
                            }}
                            wrapperSx={{ mt: 3 }}
                          />
                        )}
                        {users.length > 0 && (
                          <CheckUserExists
                            clientId={clientId}
                            formContext={formContext}
                            users={users}
                          />
                        )}
                      </Card>
                    </Box>
                  </Box>

                  <FormError>{formContext.submitError}</FormError>
                  <ButtonGroups sx={{ justifyContent: 'center', mt: 6 }}>
                    <Button
                      link={`/client/${clientId}`}
                      variant="buttons.cancel"
                    >
                      Cancel
                    </Button>
                    <Button
                      submitting={formContext.submitting}
                      type="submit"
                      width="submitButton"
                    >
                      Create Group
                    </Button>
                  </ButtonGroups>

                  <Modal
                    bodySx={{
                      px: 0,
                    }}
                    closeText="No"
                    footer={<></>}
                    isOpen={showModal}
                    onClose={toggleModal}
                    onSubmit={formContext.handleSubmit}
                    style={{
                      content: {
                        maxWidth: '800px',
                        overflowY: 'auto',
                      },
                    }}
                    submitText="Yes"
                    submitting={formContext.submitting}
                  >
                    <RoleFormSection navigate={navigate} />
                  </Modal>
                </form>
              );
            }}
          />
        )}
      </Mutation>
    </Route>
  );
};

ClientGroupCreatePage.propTypes = {
  clientId: PropTypes.string,
  navigate: PropTypes.func.isRequired,
};

ClientGroupCreatePage.defaultProps = {
  clientId: null,
};

export default ClientGroupCreatePage;
