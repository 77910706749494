import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Box from '@shoreag/base/Box';
import { ThemeContext } from 'styled-components';
import { isNumber } from 'lodash';

const ButtonGroups = ({ children, gap, sx }) => {
  const theme = useContext(ThemeContext);
  const isGapNumber = isNumber(gap);
  const spaceGap = (isGapNumber && theme.space[gap]) || gap;
  const buttonGap = `calc(${spaceGap} / 2)`;
  const wrapperMargin = `calc((${spaceGap} / 2 ) * -1)`;
  return (
    <Box
      sx={{
        alignItems: 'flex-start',
        display: 'flex',
        flexWrap: 'wrap',
        m: wrapperMargin,
        ...sx,
      }}
    >
      {React.Children.map(children, (child, index) => {
        return (
          <Box
            key={index}
            sx={{
              p: buttonGap,
            }}
          >
            {child}
          </Box>
        );
      })}
    </Box>
  );
};

ButtonGroups.defaultProps = {
  gap: 2,
  sx: {},
};

ButtonGroups.propTypes = {
  children: PropTypes.node.isRequired,
  gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sx: PropTypes.shape({}),
};

export default ButtonGroups;
