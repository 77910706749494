import PropTypes from 'prop-types';
import React from 'react';
import Box from '@shoreag/base/Box';
import { get } from 'lodash';
import { TooltipBox, TooltipTrigger } from '@shoreag/base/Tooltip';
import { Query } from 'react-apollo';
import Icon from '../Icon';
import clientCheckUserQuery from '../../graphql/queries/client-check-user.gql';

const CheckUserExists = ({ clientId, formContext, viewOnly, wrapperSx }) => {
  const users = get(formContext.values, 'users', []).map((user) => ({
    label: typeof user === 'string' ? user : user.email,
  }));
  return (
    <>
      <Box
        height={users.length > 4 ? '250px' : 'auto'}
        mt={6}
        overflowY="auto"
        sx={{
          ...wrapperSx,
        }}
      >
        {users.map((item, index) => {
          const { label: email } = item;
          return (
            <Query
              fetchPolicy="network-only"
              query={clientCheckUserQuery}
              variables={{
                clientId,
                email,
              }}
            >
              {({ data, loading }) => {
                const userExists = get(data, 'clientCheckUser.exists', null);
                return (
                  <Box
                    key={`${email}-${index}`}
                    sx={{
                      borderBottom: '1px solid',
                      borderColor: 'grays.2',
                      display: 'flex',
                      px: 2,
                      py: 4,
                    }}
                  >
                    <Box>{email}</Box>
                    {loading && '...'}
                    {!loading && userExists && (
                      <TooltipTrigger
                        data-tip="User is already assigned to another client"
                        id="tooltip"
                        sx={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Icon
                          svg="error"
                          sx={{
                            display: 'inline-block',
                            fill: 'warning',
                            flex: 'none',
                            ml: 1,
                            p: 0,
                            width: '1.1rem',
                          }}
                        />
                      </TooltipTrigger>
                    )}
                    {!viewOnly ? (
                      <Box
                        onClick={() => {
                          formContext.form.change(
                            `users`,
                            get(formContext.values, 'users', []).filter(
                              (p) => p.email !== email
                            )
                          );
                        }}
                        sx={{
                          color: 'accent',
                          cursor: 'pointer',
                          fontSize: 2,
                          ml: 'auto',
                        }}
                      >
                        Remove
                      </Box>
                    ) : null}
                  </Box>
                );
              }}
            </Query>
          );
        })}
      </Box>
      <TooltipBox id="tooltip" />
    </>
  );
};

CheckUserExists.propTypes = {
  clientId: PropTypes.string,
  formContext: PropTypes.shape({
    form: PropTypes.shape({
      change: PropTypes.func,
    }),
    values: PropTypes.shape({}),
  }).isRequired,
  viewOnly: PropTypes.bool,
  wrapperSx: PropTypes.shape({}),
};

CheckUserExists.defaultProps = {
  clientId: null,
  viewOnly: false,
  wrapperSx: {},
};

export default CheckUserExists;
