import React, { useMemo, useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from 'react-apollo';
import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import DropdownMenu from '@shoreag/base/DropdownMenu';
import Table from '@shoreag/base/Table';
import Modal from '@shoreag/base/Modal';
import { ThemeContext } from 'styled-components';
import { TooltipBox } from '@shoreag/base/Tooltip';
import { Form } from 'react-final-form';
import { get, isEmpty, noop } from 'lodash';
import Route from '../Route';
import ToolbarButton from '../ToolbarButton';
import UserSearchFilterForm from '../UserSearchFilterForm';
import DisplayUserSearchName from '../DisplayUserSearchName';
import DisplayRolesData from '../DisplayRolesData';
import DisplayTradingPartners from '../DisplayTradingPartners';
import DotsIcon from '../DotsIcon';
import TagTooltipBox from '../TagTooltip/TagTooltipBox';
import DashboardPaginator from '../DashboardPaginator';
import renderDropdownMenuItem from '../../utilities/render-dropdown-menu-item';
import dashboardConstants from '../../utilities/dashboard-constants';
import useSnackbar from '../../utilities/use-snackbar';
import setDashboardURL from '../../utilities/set-dashboard-url';
import allClientUserQuery from '../../graphql/queries/all-client-user.gql';
import deleteClientUserMutation from '../../graphql/mutations/delete-client-user.gql';
import deleteClientGroupMutation from '../../graphql/mutations/delete-client-group.gql';

const ClientDirectoryDashboardPage = ({ clientId, location, navigate }) => {
  const theme = useContext(ThemeContext);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(null);
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  const { filters, sortCriteria, pageCriteria } = useMemo(
    () =>
      dashboardConstants.getCriterias({
        search: location.search,
        type: 'client_users',
      }),
    [location.search]
  );

  const [deleteClientUser] = useMutation(deleteClientUserMutation);
  const [deleteClientGroup] = useMutation(deleteClientGroupMutation);

  const [setSnack] = useSnackbar();
  const [setErrorSnack] = useSnackbar({ error: true });

  const { values } = filters;

  const { data, loading, refetch } = useQuery(allClientUserQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      searchBody: JSON.stringify({
        clientId,
        limit: pageCriteria.pageSize,
        name: values?.name || '',
        offset: pageCriteria.offset,
        optimized: true,
        roleId: values?.roleId || '',
        status: values?.status || '',
      }),
    },
  });

  const allUsers = useMemo(() => get(data, 'allClientUser.users', []), [data]);

  const handleDelete = useCallback(
    async (user, isGroup) => {
      setDeleteInProgress(true);
      try {
        const mutation = isGroup ? deleteClientGroup : deleteClientUser;
        const variables = isGroup
          ? { groupName: user.groupName, id: user.id }
          : { clientId, email: user.email, id: user.id };

        const { data } = await mutation({
          update: (cache) => {
            cache.writeQuery({
              data: {
                allClientUser: {
                  users: allUsers.filter((u) => u.id !== user.id),
                },
              },
              query: allClientUserQuery,
            });
          },
          variables,
        });

        setSnack(
          <Box sx={{ textAlign: 'center' }}>
            {isGroup
              ? `User group: ${data.deleteClientGroup.groupName} deleted `
              : `User: ${data.deleteClientUser.email} deleted `}
            <Box as="span" sx={{ color: 'success' }}>
              successfully.
            </Box>
          </Box>
        );
        // navigate(
        //   isGroup ? `/client/${clientId}` : `/client/${clientId}/all-users`
        // );
        await refetch();
      } catch (e) {
        setErrorSnack('Deletion failed, try again later');
      } finally {
        setDeleteInProgress(false);
        setShowDeleteConfirmation(null);
      }
    },
    [
      deleteClientUser,
      deleteClientGroup,
      allUsers,
      setSnack,
      setErrorSnack,
      navigate,
      clientId,
    ]
  );

  const setDisplayName = (user) => {
    let displayName = '';
    if (user.groupName) {
      displayName = user.groupName;
    } else if (user.firstName || user.lastName) {
      displayName = `${user.firstName} ${user.lastName}`;
    } else if (user.email) {
      displayName = user.email;
    } else {
      displayName = '-NA-';
    }
    return displayName;
  };

  const tableRows = useMemo(
    () =>
      allUsers.map((user) => {
        const isGroup = Boolean(user.groupName);
        const dropdownItems = isGroup
          ? [
              ['edit', 'Edit Group'],
              ['close', 'Delete Group'],
            ]
          : [
              ['edit', 'Edit User'],
              ['close', 'Delete User'],
            ];

        const dropdownActions = isGroup
          ? [
              `/client/${clientId}/group/${user.id}/edit`,
              // () => handleDelete(user, true),
              () => setShowDeleteConfirmation({ isGroup: true, user }),
            ]
          : [
              `/client/${clientId}/user/${user.id}/edit`,
              () => setShowDeleteConfirmation({ isGroup: false, user }),
            ];

        return [
          <DisplayUserSearchName
            icon={isGroup ? 'folder' : 'user'}
            name={setDisplayName(user)}
          />,
          user.users ? <Box color="grays.4">{user.users}</Box> : '-',
          user.role ? (
            <Box color="grays.4" fontWeight={600}>
              {user.role}
            </Box>
          ) : (
            <DisplayRolesData roles={user.roles} /> || '-'
          ),
          user.partners?.length ? (
            <DisplayTradingPartners partners={user.partners} />
          ) : (
            '-'
          ),
          <Box sx={{ float: 'right' }}>
            <DropdownMenu
              button={<DotsIcon />}
              itemActions={dropdownActions}
              items={dropdownItems.map(renderDropdownMenuItem)}
            />
          </Box>,
        ];
      }),
    [allUsers, clientId, handleDelete]
  );

  return (
    <Route
      header={{
        rightContainer: (
          <Box
            sx={{
              display: 'flex',
              gap: 2,
            }}
          >
            <ToolbarButton
              active
              icon="plus"
              label="User"
              link={`/client/${clientId}/create-user`}
            />
            <ToolbarButton
              icon="plus"
              label="Group"
              link={`/client/${clientId}/create-group`}
            />
          </Box>
        ),
        title: 'User management',
      }}
      isPrivate
    >
      <UserSearchFilterForm
        clientId={clientId}
        filters={filters}
        page={pageCriteria}
        sort={sortCriteria}
      />
      <Form
        initialValues={{
          name: values?.name || '',
          roleId: values?.roleId || '',
          status: values?.status || '',
        }}
        onSubmit={noop}
        render={({ handleSubmit }) => {
          return (
            <Box
              as="form"
              onSubmit={handleSubmit}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
              }}
            >
              <Card m={0} p={5} sx={{ borderRadius: 0 }}>
                <Table
                  header={[
                    'Name',
                    'No of Users',
                    'Role',
                    'Trading Partners',
                    '',
                  ]}
                  isLoading={loading}
                  rows={tableRows}
                />
                <TooltipBox id="tooltip" />
                <TagTooltipBox />
              </Card>
              <DashboardPaginator
                filters={{
                  page: pageCriteria.offset,
                }}
                goToNextPage={() =>
                  navigate(
                    setDashboardURL({
                      filters,
                      filterValues: filters.values,
                      page: {
                        offset: pageCriteria.offset + pageCriteria.pageSize,
                        pageSize: pageCriteria.pageSize,
                      },
                      pathname: location.pathname,
                      sort: sortCriteria,
                    }),
                    { replace: true }
                  )
                }
                goToPreviousPage={() =>
                  navigate(
                    setDashboardURL({
                      filters,
                      filterValues: filters.values,
                      page: {
                        offset: pageCriteria.offset - pageCriteria.pageSize,
                        pageSize: pageCriteria.pageSize,
                      },
                      pathname: location.pathname,
                      sort: sortCriteria,
                    }),
                    { replace: true }
                  )
                }
                isClientSidePagination
                pageTotal={allUsers.length}
              />
            </Box>
          );
        }}
      />
      {!isEmpty(showDeleteConfirmation) && (
        <Modal
          closeText="Cancel"
          isOpen={!!showDeleteConfirmation}
          onClose={() => {
            setShowDeleteConfirmation(null);
          }}
          onSubmit={() => {
            const { isGroup, user } = showDeleteConfirmation;
            handleDelete(user, isGroup);
          }}
          style={{
            content: {
              maxWidth: theme.sizes.maxWidths.form,
            },
          }}
          submitText="Delete"
          submitting={deleteInProgress}
          title={
            showDeleteConfirmation.isGroup ? 'Delete user group' : 'Delete user'
          }
        >
          Are sure you want to delete the{' '}
          {showDeleteConfirmation.isGroup ? 'user group' : 'user'}{' '}
          <strong>{setDisplayName(showDeleteConfirmation.user)}</strong>?
        </Modal>
      )}
    </Route>
  );
};

ClientDirectoryDashboardPage.propTypes = {
  clientId: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
};

ClientDirectoryDashboardPage.defaultProps = {
  clientId: null,
};

export default ClientDirectoryDashboardPage;
