import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Spinner from '@shoreag/base/Spinner';
import get from 'lodash/get';
import { AuthContext } from '@shoreag/auth';
import { ThemeContext } from 'styled-components';
import { Field, Form as FinalForm } from 'react-final-form';
// import { composeValidations, email, required } from '@shoreag/validations';
import Box from '@shoreag/base/Box';
import Button from '@shoreag/base/Button';
import Dropdown from '@shoreag/base/Dropdown';
import Card from '@shoreag/base/Card';
import Input from '@shoreag/base/Input';
import { FORM_ERROR } from 'final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Mutation, useQuery } from 'react-apollo';
import ToggleSwitch from '@shoreag/base/ToggleSwitch';
import { required } from '@shoreag/validations';
import arrayMutators from 'final-form-arrays';
import FormError from '../FormError';
import clientFeatureQuery from '../../graphql/queries/client-feature.gql';
import NotFoundPage from '../../routes/default/404';
import Route from '../Route';
import createClientMutation from '../../graphql/mutations/create-client.gql';
import PillsGroup from '../PillsGroup';
import useSnackbar from '../../utilities/use-snackbar';
import { DEFAULT_GRID_GAP } from '../../utilities/constants';
import AddButton from '../AddButton';
import AllPartnersDropdown from '../AllPartnersDropdown';
import CheckUserExists from '../CheckUserExists';
import ButtonGroups from '../ButtonGroups';

const ClientCreatePage = ({ clientId, navigate }) => {
  const {
    defaultStyles: { twoColumnGrid },
  } = useContext(ThemeContext);
  const { isAuthenticated, user } = useContext(AuthContext);
  if (isAuthenticated && !user.isAdmin) return <NotFoundPage />;

  const { data, loading } = useQuery(clientFeatureQuery, {
    fetchPolicy: 'network-only',
  });
  const [setSnack] = useSnackbar();
  const clientFeature = get(data, 'clientFeature', []);
  const gridCardStyle = {
    minHeight: '100%',
    mt: 0,
  };
  if (loading) return <Spinner />;
  return (
    <Route
      header={{
        title: `${clientId ? 'Update' : 'Create'} Client`,
      }}
      isPrivate
    >
      <Mutation mutation={createClientMutation}>
        {(createClient) => (
          <FinalForm
            initialValues={{
              capacity: 0,
              clientName: '',
              features: [],
              partners: [],
              users: [],
            }}
            mutators={arrayMutators}
            onSubmit={async (values) => {
              const updatedFeature = clientFeature
                .filter((feature, index) => values?.features?.[index])
                .map((feature) => feature.id);
              const payload = {
                variables: {
                  capacity: values?.capacity ? values.capacity : 0,
                  clientName: values?.clientName ? values.clientName : '',
                  features: updatedFeature || [],
                  partners: (values?.partners || []).map(
                    (partner) => partner.id
                  ),
                  users: values?.users ? values.users : [],
                },
              };
              try {
                const {
                  data: {
                    createClient: { clientName },
                  },
                } = await createClient(payload);
                if (clientName) {
                  setSnack(
                    <Box sx={{ textAlign: 'center' }}>
                      Client: <b>{clientName}</b> is created
                      <Box as="span" sx={{ color: 'success' }}>
                        successfully.
                      </Box>
                    </Box>
                  );
                  navigate('/client/');
                }
              } catch (e) {
                return {
                  [FORM_ERROR]: get(e, 'graphQLErrors[0].message', e.message),
                };
              }
            }}
            render={(formContext) => {
              const isPartnerAssociated =
                formContext?.values?.tradingPartnersAssociate;

              const users = formContext?.values?.users || [];
              return (
                <form onSubmit={formContext.handleSubmit}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      m: `-${DEFAULT_GRID_GAP / 2}px`,
                    }}
                  >
                    <Box
                      sx={{
                        ...twoColumnGrid,
                        p: 0,
                      }}
                    >
                      <Box sx={{ ...twoColumnGrid, width: '100%' }}>
                        <Card sx={gridCardStyle}>
                          <Box as="h2" sx={{ fontSize: 5, mb: 6, mt: 0 }}>
                            Details
                          </Box>
                          <Field
                            component={Input}
                            label="Name"
                            name="clientName"
                            validate={required}
                            wrapperSx={{ mb: 5 }}
                          />
                          <Field
                            component={Dropdown}
                            label="Capacity (Max Users)"
                            name="capacity"
                            options={[
                              { label: '10', value: 10 },
                              { label: '20', value: 20 },
                              { label: '30', value: 30 },
                            ]}
                            wrapperSx={{ mb: 5 }}
                          />
                          <Field
                            component={ToggleSwitch}
                            errorSx={{
                              ml: 4,
                              mt: 0,
                            }}
                            label="Associate Trading Partners"
                            labelSx={{
                              ml: 0,
                              mr: 3,
                              order: 'unset',
                            }}
                            name="tradingPartnersAssociate"
                            type="checkbox"
                            wrapperSx={{
                              alignItems: 'center',
                              display: 'flex',
                            }}
                          />
                          {isPartnerAssociated && (
                            <AllPartnersDropdown
                              isMulti
                              isRequired={false}
                              name="partners"
                              showLabel={false}
                              wrapperSx={{
                                mt: 3,
                              }}
                            />
                          )}
                          <PillsGroup
                            data={get(formContext.values, `partners`, []).map(
                              (partner) => ({
                                label: partner.partnerName,
                              })
                            )}
                            onRemove={(parameter) =>
                              formContext.form.change(
                                `partners`,
                                get(formContext.values, `partners`, []).filter(
                                  (p) => p.partnerName !== parameter.label
                                )
                              )
                            }
                            pillSx={{
                              '& a': { textDecoration: 'none' },
                              '& div': { bg: 'accent', color: 'white' },
                              '&:hover': { bg: 'accent' },
                              bg: 'accent',
                              borderRadius: '1rem',
                            }}
                            wrapperSx={{
                              pt: 4,
                            }}
                          />
                        </Card>
                      </Box>
                      <Box sx={{ ...twoColumnGrid, width: '100%' }}>
                        <Card sx={gridCardStyle}>
                          <Box as="h2" sx={{ fontSize: 4, mb: 2, mt: 0 }}>
                            Invite user(s)
                          </Box>
                          <Box
                            sx={{
                              color: 'grays.4',
                              fontSize: 2,
                              mt: 1,
                            }}
                          >
                            This user(s) is the admin. The admin will have all
                            the permissions of this client.
                          </Box>
                          {/* AllClientUserDropdown */}
                          <Field
                            component={Input}
                            label="Invite User"
                            name="newUser"
                            // validate={required}
                            wrapperSx={{ mb: 5 }}
                          />
                          <AddButton
                            label="Add user"
                            labelSx={{
                              color: 'accent',
                            }}
                            onClick={() => {
                              const {
                                newUser,
                              } = formContext.form.getState().values;
                              if (newUser) {
                                formContext.form.change('users', [
                                  ...formContext.form.getState().values.users,
                                  newUser,
                                ]);
                                formContext.form.change('newUser', ''); // Clear the input field after adding the user
                              }
                            }}
                            sx={{
                              '&:hover': { bg: 'accentLight' },
                              alignItems: 'center',
                              bg: 'accent',
                              display: 'flex',
                              fontSize: 4,
                              height: '16px',
                              justifyContent: 'center',
                              width: '16px',
                            }}
                            wrapperSx={{ mt: 3 }}
                          />

                          {users.length > 0 && (
                            <CheckUserExists
                              formContext={formContext}
                              users={users}
                            />
                          )}
                        </Card>
                      </Box>
                    </Box>
                    <Box sx={twoColumnGrid}>
                      <Card sx={gridCardStyle}>
                        <Box as="h2" sx={{ fontSize: 4, mb: 5, mt: 0 }}>
                          Features
                        </Box>
                        <FieldArray name="features">
                          {() =>
                            clientFeature.map((feature, index) => (
                              <Box
                                key={feature.id}
                                alignItems="center"
                                display="flex"
                                justifyContent="space-between"
                                sx={{
                                  borderBottom: '1px solid',
                                  borderColor: 'gray2',
                                  pb: 4,
                                  pt: 5,
                                }}
                              >
                                <Box fontSize={3} sx={{ color: 'grays.4' }}>
                                  {feature.featuresName}
                                </Box>
                                <Field
                                  component={ToggleSwitch}
                                  name={`features[${index}]`}
                                  type="checkbox"
                                  wrapperSx={{
                                    flex: 'none',
                                    m: 0,
                                  }}
                                />
                              </Box>
                            ))
                          }
                        </FieldArray>
                      </Card>
                    </Box>
                  </Box>

                  <FormError>{formContext.submitError}</FormError>
                  <ButtonGroups sx={{ justifyContent: 'center', mt: 6 }}>
                    <Button link="/client" variant="buttons.cancel">
                      Cancel
                    </Button>
                    {clientId ? (
                      <Button width="submitButton">Update Client</Button>
                    ) : (
                      <Button
                        submitting={formContext.submitting}
                        type="submit"
                        width="submitButton"
                      >
                        Create Client
                      </Button>
                    )}
                  </ButtonGroups>
                </form>
              );
            }}
          />
        )}
      </Mutation>
    </Route>
  );
};

ClientCreatePage.propTypes = {
  clientId: PropTypes.string,
  navigate: PropTypes.func.isRequired,
};

ClientCreatePage.defaultProps = {
  clientId: null,
};

export default ClientCreatePage;
