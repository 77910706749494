import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import get from 'lodash/get';
import { AuthContext } from '@shoreag/auth';
import { ThemeContext } from 'styled-components';
import { Field, Form as FinalForm } from 'react-final-form';
// import { composeValidations, email, required } from '@shoreag/validations';
import Box from '@shoreag/base/Box';
// import Modal from '@shoreag/base/Modal';
import Input from '@shoreag/base/Input';
import Button from '@shoreag/base/Button';
import { FORM_ERROR } from 'final-form';
import Dropdown from '@shoreag/base/Dropdown';
import Card from '@shoreag/base/Card';
import { Mutation, useQuery } from 'react-apollo';
import Spinner from '@shoreag/base/Spinner';
// import AddButton from '../AddButton';
import updateClientGroup from '../../graphql/mutations/update-client-group.gql';
import useSnackbar from '../../utilities/use-snackbar';
import { DEFAULT_GRID_GAP } from '../../utilities/constants';
import AllPartnersDropdown from '../AllPartnersDropdown';
import AllClientUserDropdown from '../AllClientUserDropdown';
import PillsGroup from '../PillsGroup';
import NotFoundPage from '../../routes/default/404';
import Route from '../Route';
// import RoleFormSection from '../RoleFormSection';
import FormError from '../FormError';
import CheckUserExists from '../CheckUserExists';
import clientGroupDetailsQuery from '../../graphql/queries/client-group-details.gql';
import ButtonGroups from '../ButtonGroups';

const allRoleDropdown = [
  {
    id: 'shore:dex:role::41ebd714-a308-440d-b7bf-590d9d24e2a4',
    roleName: 'Text 1',
  },
  {
    id: 'shore:dex:role::34894d56-f228-4eb1-b67d-3188c5718636',
    roleName: 'Admin',
  },
  {
    id: 'shore:dex:role::b8edb391-267b-45c5-b99d-6b954c2f5b1d',
    roleName: 'Trust 1 Update',
  },
];
const ClientGroupEditPage = ({ clientId, navigate, groupId }) => {
  const {
    defaultStyles: { twoColumnGrid },
  } = useContext(ThemeContext);
  const { user: authUser } = useContext(AuthContext);
  if (!authUser.isAdmin) return <NotFoundPage />;

  // const [showModal, setShowModal] = useState(false);
  // const [showInviteBox, setShowInviteBox] = useState(false);

  const { data, loading } = useQuery(clientGroupDetailsQuery, {
    fetchPolicy: 'network-only',
    skip: !groupId,
    variables: { groupId: groupId || '' },
  });

  const groupData = get(data, 'clientGroupDetails', {});

  if (loading) return <Spinner />;

  const partners = get(groupData, 'partners', []);
  const initialValues = {
    groupName: groupData.groupName || '',
    partners: partners.map((partner) => {
      return {
        id: partner.id,
        partnerName: partner.partnerName,
      };
    }),
    roleId: get(groupData, 'role', ''),
    users: (groupData?.users || []).map((users) => {
      return {
        email: users.email,
        id: users.id,
      };
    }),
  };

  // const toggleModal = () => {
  //   setShowModal(!showModal);
  // };
  const [setSnack] = useSnackbar();
  // const newRoleButton = (
  //   <Box
  //     onClick={toggleModal}
  //     sx={{
  //       color: 'accent',
  //       cursor: 'pointer',
  //     }}
  //   >
  //     Create new role
  //   </Box>
  // );

  const gridCardStyle = {
    minHeight: '100%',
    mt: 0,
  };

  return (
    <Route
      header={{
        icon: 'users',
        title: 'Update Group',
        type: 'User',
      }}
      isPrivate
    >
      <Mutation mutation={updateClientGroup}>
        {(updateClientGroup) => (
          <FinalForm
            initialValues={initialValues}
            onSubmit={async (values) => {
              const payload = {
                variables: {
                  groupName: values?.groupName ? values.groupName : '',
                  partners: (values?.partners || []).map(
                    (partner) => partner.id
                  ),
                  role: values?.roleId ? values.roleId : '',
                  users: (values?.users || []).map((user) => user.email),
                },
              };
              try {
                const {
                  data: {
                    updateClientGroup: { groupName },
                  },
                } = await updateClientGroup({
                  ...payload.variables,
                  id: groupData.id,
                });

                if (groupName) {
                  setSnack(
                    <Box sx={{ textAlign: 'center' }}>
                      UserGroup:
                      <b> {groupName}</b>
                      is updated&nbsp;
                      <Box as="span" sx={{ color: 'success' }}>
                        successfully.
                      </Box>
                    </Box>
                  );
                }

                navigate(`/client/${clientId}`);
              } catch (e) {
                return {
                  [FORM_ERROR]: get(e, 'graphQLErrors[0].message', e.message),
                };
              }
            }}
            render={(formContext) => {
              const users = formContext?.values?.users || [];
              return (
                <form onSubmit={formContext.handleSubmit}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      m: `-${DEFAULT_GRID_GAP / 2}px`,
                    }}
                  >
                    <Box sx={twoColumnGrid}>
                      <Card sx={gridCardStyle}>
                        <Box as="h2" sx={{ fontSize: 5, mb: 6, mt: 0 }}>
                          Details
                        </Box>
                        <Field
                          component={Input}
                          label="Enter Group Name"
                          name="groupName"
                          wrapperSx={{ mb: 5 }}
                        />
                        <Field
                          component={Dropdown}
                          label="Role"
                          name="roleId"
                          options={allRoleDropdown.map(({ id, roleName }) => ({
                            label: roleName || '-',
                            value: id,
                          }))}
                        />
                        <Box
                          sx={{
                            color: 'accentSecondary',
                            fontSize: 2,
                            mb: 5,
                            mt: 1,
                          }}
                        >
                          This role will be assigned to all the users belonging
                          to this group
                        </Box>
                        <AllPartnersDropdown
                          formContext={formContext}
                          isMulti
                          isRequired={false}
                          name="partners"
                          showLabel
                          wrapperSx={{
                            mt: 3,
                          }}
                        />

                        <PillsGroup
                          data={get(formContext.values, `partners`, []).map(
                            (partner) => ({
                              label: partner.partnerName,
                            })
                          )}
                          onRemove={(parameter) =>
                            formContext.form.change(
                              `partners`,
                              get(formContext.values, `partners`, []).filter(
                                (p) => p.partnerName !== parameter.label
                              )
                            )
                          }
                          pillSx={{
                            bg: 'accent',
                            borderRadius: '1rem',
                          }}
                          wrapperSx={{
                            pt: 4,
                          }}
                        />
                      </Card>
                    </Box>
                    <Box sx={twoColumnGrid}>
                      <Card sx={gridCardStyle}>
                        <Box as="h2" sx={{ fontSize: 4, mb: 2, mt: 0 }}>
                          Invite user(s)
                        </Box>
                        <Box
                          sx={{
                            color: 'grays.4',
                            fontSize: 2,
                            mt: 1,
                          }}
                        >
                          This user(s) is the admin. The admin will have all the
                          permissions of this client.
                        </Box>
                        <AllClientUserDropdown
                          clientId={clientId}
                          isMulti
                          isRequired={false}
                          name="users"
                          showLabel={false}
                        />
                        {users.length > 0 && (
                          <CheckUserExists
                            clientId={clientId}
                            formContext={formContext}
                            users={users}
                          />
                        )}
                        {/* {showInviteBox && (
                        <Box
                          mt={4}
                          sx={{
                            alignItems: 'flex-end',
                            display: 'flex',
                            gap: '10px',
                          }}
                        >
                          <Field
                            component={Input}
                            label="Invite New User"
                            name="newUsers"
                            validate={composeValidations(required, email)}
                          />
                          <Button>Invite</Button>
                        </Box>
                      )}
                      <AddButton
                        label="Add user"
                        labelSx={{
                          color: 'accent',
                        }}
                        onClick={() => setShowInviteBox(!showInviteBox)}
                        sx={{
                          '&:hover': { bg: 'accentLight' },
                          alignItems: 'center',
                          bg: 'accent',
                          display: 'flex',
                          fontSize: 4,
                          height: '16px',
                          justifyContent: 'center',
                          width: '16px',
                        }}
                        wrapperSx={{ mt: 3 }}
                      /> */}
                      </Card>
                    </Box>
                  </Box>
                  <FormError>{formContext.submitError}</FormError>
                  <ButtonGroups sx={{ justifyContent: 'center', mt: 6 }}>
                    <Button
                      link={`/client/${clientId}`}
                      variant="buttons.cancel"
                    >
                      Cancel
                    </Button>
                    <Button
                      submitting={formContext.submitting}
                      type="submit"
                      width="submitButton"
                    >
                      Update Group
                    </Button>
                  </ButtonGroups>

                  {/* <Modal
                    bodySx={{
                      px: 0,
                    }}
                    closeText="No"
                    footer={<></>}
                    isOpen={showModal}
                    onClose={toggleModal}
                    onSubmit={formContext.handleSubmit}
                    style={{
                      content: {
                        maxWidth: '800px',
                        overflowY: 'auto',
                      },
                    }}
                    submitText="Yes"
                    submitting={formContext.submitting}
                    title="Create new role"
                  >
                    <RoleFormSection navigate={navigate} />
                  </Modal> */}
                </form>
              );
            }}
          />
        )}
      </Mutation>
    </Route>
  );
};

ClientGroupEditPage.propTypes = {
  clientId: PropTypes.string,
  groupId: PropTypes.string,
  navigate: PropTypes.func.isRequired,
};

ClientGroupEditPage.defaultProps = {
  clientId: null,
  groupId: null,
};

export default ClientGroupEditPage;
