import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import Spinner from '@shoreag/base/Spinner';
import { useQuery } from 'react-apollo';
import Button from '@shoreag/base/Button';
import partnerQuery from '../../graphql/queries/partner-information.gql';
import parseUuid from '../../utilities/parse-uuid';
import ResponsiveEllipsis from '../ResponsiveEllipsis';
import { REGEXES, UUID_PREFIXES } from '../../utilities/constants';

const { UUID } = REGEXES;
const { PARTNER } = UUID_PREFIXES;

const DisplayPartnerData = ({ partnerId, fetchPolicy }) => {
  const isValidPartnerId = new RegExp(`^${PARTNER}${UUID}$`).test(partnerId);
  if (isValidPartnerId) {
    const { id } = parseUuid(partnerId);
    const { data, loading } = useQuery(partnerQuery, {
      fetchPolicy,
      skip: !id,
      variables: { id },
    });
    const partnerData = get(
      data,
      'partner.partnerDetailsAndContacts.partnerInformation',
      {}
    );

    if (loading) {
      return (
        <Spinner
          justifyContent="left"
          py={1}
          sx={{
            transform: 'scale(0.5)',
            transformOrigin: '0',
          }}
          width="100px"
        />
      );
    }

    return data ? (
      <Button
        link={`/partners/${partnerId}`}
        simple
        sx={{
          display: 'inline-block',
        }}
        title={partnerData.partnerName || partnerId}
        variant="buttons.link"
      >
        <ResponsiveEllipsis text={partnerData.partnerName || partnerId} />
      </Button>
    ) : (
      '-'
    );
  }
  return partnerId;
};

DisplayPartnerData.defaultProps = {
  fetchPolicy: 'cache-first',
  partnerId: null,
};

DisplayPartnerData.propTypes = {
  fetchPolicy: PropTypes.string,
  partnerId: PropTypes.string,
};

export default DisplayPartnerData;
