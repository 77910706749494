import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from 'react-apollo';
import { get } from 'lodash';
import Box from '@shoreag/base/Box';
import Spinner from '@shoreag/base/Spinner';
import DisplayTradingPartners from '../../DisplayTradingPartners';
import clientGroupDetailsQuery from '../../../graphql/queries/client-group-details.gql';

const ClientGroupDetails = ({ groupId }) => {
  const { data, loading } = useQuery(clientGroupDetailsQuery, {
    fetchPolicy: 'network-only',
    variables: { groupId },
  });

  const clientGroupDetails = get(data, 'clientGroupDetails', {});

  if (loading) return <Spinner />;

  return (
    <Box mt="6" pt="6">
      <Box
        sx={{
          borderBottomStyle: 'solid',
          borderColor: 'grays.2',
          borderWidth: '1px',
          color: 'grays.4',
          fontSize: 3,
          mb: 1,
          mt: 6,
          pb: 2,
        }}
      >
        {clientGroupDetails.groupName} details
      </Box>
      <Box alignItems="center" display="flex" mb="4" mt="4">
        <Box sx={{ color: 'grays.4', fontSize: 3, width: '160px' }}>Role:</Box>
        <Box>{clientGroupDetails.role}</Box>
      </Box>
      <Box alignItems="center" display="flex" my="3">
        <Box sx={{ color: 'grays.4', fontSize: 3, width: '160px' }}>
          Trading partner(s):
        </Box>
        {clientGroupDetails.partners ? (
          <DisplayTradingPartners partners={clientGroupDetails.partners} />
        ) : (
          '-NA-'
        )}
      </Box>
    </Box>
  );
};

ClientGroupDetails.propTypes = {
  groupId: PropTypes.string.isRequired,
};

export default ClientGroupDetails;
