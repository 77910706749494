import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useMutation } from 'react-apollo';
import { Field, Form as FinalForm } from 'react-final-form';
import get from 'lodash/get';
import { FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { composeValidations, email, required } from '@shoreag/validations';
import Checkbox from '@shoreag/base/Checkbox';
import Box from '@shoreag/base/Box';
import Button from '@shoreag/base/Button';
import Input from '@shoreag/base/Input';
import Card from '@shoreag/base/Card';
import useSnackbar from '../../utilities/use-snackbar';
import Route from '../Route';
import FormError from '../FormError';
import AllClientGroupDropdown from '../AllClientGroupDropdown';
import createClientUser from '../../graphql/mutations/create-client-user.gql';
import ClientGroupDetails from './ClientGroupDetails';
import ButtonGroups from '../ButtonGroups';

const ClientUserCreatePage = ({ clientId, navigate }) => {
  const [setSnack] = useSnackbar();
  const [createUser, { loading: isSubmitting }] = useMutation(createClientUser);

  const handleSubmit = useCallback(
    async (values) => {
      try {
        const { data } = await createUser({
          variables: {
            clientId,
            email: values.email || '',
            groupId: values.groups?.id || '',
          },
        });

        if (data?.createClientUser?.email) {
          setSnack(
            <Box sx={{ textAlign: 'center' }}>
              User: <b>{data.createClientUser.email}</b> is created&nbsp;
              <Box as="span" sx={{ color: 'success' }}>
                successfully.
              </Box>
            </Box>
          );
          navigate(`/client/${clientId}`);
        }
      } catch (e) {
        return { [FORM_ERROR]: get(e, 'graphQLErrors[0].message', e.message) };
      }
    },
    [clientId, createUser, navigate, setSnack]
  );

  return (
    <Route
      header={{
        icon: 'users',
        title: 'Create User',
        type: 'User',
      }}
      isPrivate
    >
      <FinalForm
        initialValues={{
          email: '',
          groupId: '',
        }}
        mutators={arrayMutators}
        onSubmit={handleSubmit}
        render={({ handleSubmit, values, form, submitError }) => {
          const isAdmin = useMemo(() => values.isAdmin, [values.isAdmin]);
          const groupId = useMemo(() => values.groups?.id, [values.groups]);
          if (isAdmin) {
            form.change('groupId', '');
          }
          return (
            <form onSubmit={handleSubmit}>
              <Card maxWidth="maxWidths.largeForm" mx="auto">
                <Box as="h2" sx={{ fontSize: 5, mb: 6, mt: 0 }}>
                  Details
                </Box>
                <Field
                  component={Input}
                  label="Invite User (Email-ID)"
                  name="email"
                  validate={composeValidations(required, email)}
                />
                <Field
                  component={Checkbox}
                  label="This user is an admin"
                  name="isAdmin"
                  type="checkbox"
                  wrapperSx={{
                    alignItems: 'center',
                    display: 'flex',
                    mb: 5,
                    mt: 5,
                  }}
                />
                <AllClientGroupDropdown
                  clientId={clientId}
                  disabled={isAdmin}
                  isClearable={false}
                  isRequired={false}
                  name="groups"
                  showLabel
                />
                <Box
                  sx={{
                    color: isAdmin ? 'grays.2' : 'accentSecondary',
                    fontSize: 2,
                    mt: 1,
                  }}
                >
                  The user you are creating will be a part of this group.
                </Box>
                {!isAdmin && groupId && (
                  <ClientGroupDetails groupId={groupId} />
                )}
              </Card>
              <FormError>{submitError}</FormError>
              <ButtonGroups sx={{ justifyContent: 'center', mt: 6 }}>
                <Button link={`/client/${clientId}`} variant="buttons.cancel">
                  Cancel
                </Button>
                <Button
                  submitting={isSubmitting}
                  type="submit"
                  width="submitButton"
                >
                  Create User
                </Button>
              </ButtonGroups>
            </form>
          );
        }}
      />
    </Route>
  );
};

ClientUserCreatePage.propTypes = {
  clientId: PropTypes.string,
  navigate: PropTypes.func.isRequired,
};

ClientUserCreatePage.defaultProps = {
  clientId: null,
};

export default ClientUserCreatePage;
