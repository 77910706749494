import React, { useCallback, useMemo, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { useQuery, useMutation } from 'react-apollo';
import { Form } from 'react-final-form';
import { get, isEmpty, noop } from 'lodash';
import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import DropdownMenu from '@shoreag/base/DropdownMenu';
import Table from '@shoreag/base/Table';
import AutoSave from '@shoreag/base/AutoSave';
import { TooltipBox } from '@shoreag/base/Tooltip';
import Modal from '@shoreag/base/Modal';
import renderDropdownMenuItem from '../../utilities/render-dropdown-menu-item';
import dashboardConstants from '../../utilities/dashboard-constants';
import setDashboardURL from '../../utilities/set-dashboard-url';
import useSnackbar from '../../utilities/use-snackbar';
import DotsIcon from '../DotsIcon';
import Route from '../Route';
import DisplayUserGroupData from '../DisplayUserGroupData';
import DisplayRolesData from '../DisplayRolesData';
import TagTooltipBox from '../TagTooltip/TagTooltipBox';
import ToolbarButton from '../ToolbarButton';
import DashboardPaginator from '../DashboardPaginator';
import DisplayUserStatus from '../DisplayUserStatus';
import DisplayTradingPartners from '../DisplayTradingPartners';
import DisplayUserSearchName from '../DisplayUserSearchName';
import UserSearchFilterForm from '../UserSearchFilterForm';
import allClientUserQuery from '../../graphql/queries/all-client-user.gql';
import deleteClientUserMutation from '../../graphql/mutations/delete-client-user.gql';
import enableDisableClientUserMutation from '../../graphql/mutations/enable-disable-client-user.gql';

const ClientUserDashboardPage = ({ clientId, location, navigate }) => {
  const theme = useContext(ThemeContext);

  const USER_ACTIONS = {
    ACTIVE: 'active',
    DELETE: 'delete',
    IN_ACTIVE: 'inactive',
  };

  const [showActionConfirmation, setActionConfirmation] = useState(null);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [setSnack] = useSnackbar();
  const [setErrorSnack] = useSnackbar({ error: true });

  const { filters, sortCriteria, pageCriteria } = useMemo(
    () =>
      dashboardConstants.getCriterias({
        search: location.search,
        type: 'client_users',
      }),
    [location.search]
  );

  const showFilter = useMemo(() => location.pathname.includes('/all-users'), [
    location.pathname,
  ]);

  const { data, loading, refetch } = useQuery(allClientUserQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      searchBody: JSON.stringify({
        clientId,
        groupId: filters.values.groupId || '',
        limit: pageCriteria.pageSize,
        name: filters.values.name || '',
        offset: pageCriteria.offset,
        roleId: filters.values.roleId || '',
        status: filters.values.status || '',
      }),
    },
  });

  const allUser = get(data, 'allClientUser.users', []);
  const groupName = get(data, 'allClientUser.groupName', '');

  const [deleteClientUser] = useMutation(deleteClientUserMutation);
  const [enableDisableClientUser] = useMutation(
    enableDisableClientUserMutation
  );

  const handleUserAction = async (user, action) => {
    setActionInProgress(true);
    try {
      if (action === USER_ACTIONS.DELETE) {
        await deleteClientUser({
          update: (cache) => {
            cache.modify({
              fields: {
                allClientUser(existingUsers = []) {
                  return existingUsers.filter((u) => u.id !== user.id);
                },
              },
            });
          },
          variables: {
            clientId,
            email: user.email,
            id: user.id,
          },
        });
        setSnack(
          <>
            User {user.email} deleted{' '}
            <Box as="span" sx={{ color: 'success' }}>
              successfully
            </Box>
            .
          </>
        );
      } else {
        await enableDisableClientUser({
          update: (cache) => {
            cache.modify({
              fields: {
                allClientUser(existingUsers = []) {
                  return existingUsers.map((u) =>
                    u.id === user.id ? { ...u, status: action } : u
                  );
                },
              },
            });
          },
          variables: {
            id: user.id,
            status: action,
          },
        });
        setSnack(
          <>
            User {action === USER_ACTIONS.ACTIVE ? 'enabled' : 'disabled'}{' '}
            <Box as="span" sx={{ color: 'success' }}>
              successfully
            </Box>
            .
          </>
        );
      }
      // navigate(window.location.pathname);
      await refetch();
    } catch (e) {
      setErrorSnack(`${action} failed, try again later.`);
    } finally {
      setActionInProgress(false);
      setActionConfirmation(null);
    }
  };

  const setDisplayName = (user) => {
    let displayName = '';
    if (user.firstName || user.lastName) {
      displayName = `${user.firstName} ${user.lastName}`;
    } else {
      displayName = '-NA-';
    }
    return displayName;
  };

  const renderUserRow = useCallback(
    (user) => {
      const dropdownItems = [
        ['edit', 'Edit'],
        ['close', 'Delete'],
      ];
      const dropdownActions = [
        `/client/${clientId}/user/${user.id}/edit`,
        () => setActionConfirmation({ action: USER_ACTIONS.DELETE, user }),
      ];

      if (user.status === USER_ACTIONS.ACTIVE) {
        dropdownItems.push(['deactivate', 'Disable User']);
        dropdownActions.push(() =>
          setActionConfirmation({ action: USER_ACTIONS.IN_ACTIVE, user })
        );
      } else if (user.status === USER_ACTIONS.IN_ACTIVE) {
        dropdownItems.push(['activate', 'Enable User']);
        dropdownActions.push(() =>
          setActionConfirmation({ action: USER_ACTIONS.ACTIVE, user })
        );
      }

      return [
        <DisplayUserSearchName icon="user" name={setDisplayName(user)} />,
        user.roles?.length ? <DisplayRolesData roles={user.roles} /> : '-',
        user.groups?.length ? (
          <DisplayUserGroupData groups={user.groups} />
        ) : (
          '-'
        ),
        user.status ? <DisplayUserStatus status={user.status} /> : '-',
        user.partners?.length ? (
          <DisplayTradingPartners partners={user.partners} />
        ) : (
          '-'
        ),
        <Box sx={{ float: 'right' }}>
          <DropdownMenu
            button={<DotsIcon />}
            itemActions={dropdownActions}
            items={dropdownItems.map(renderDropdownMenuItem)}
          />
        </Box>,
      ];
    },
    [clientId]
  );

  const isDeleteTriggered =
    !isEmpty(showActionConfirmation) &&
    showActionConfirmation.action === USER_ACTIONS.DELETE;

  const isStatusToggleTriggered =
    !isEmpty(showActionConfirmation) &&
    [USER_ACTIONS.ACTIVE, USER_ACTIONS.IN_ACTIVE].includes(
      showActionConfirmation.action
    );

  return (
    <Route
      header={{
        rightContainer: (
          <Box
            sx={{
              display: 'flex',
              gap: 2,
            }}
          >
            <ToolbarButton
              active
              icon="plus"
              label="User"
              link={`/client/${clientId}/create-user`}
            />
            <ToolbarButton
              icon="plus"
              label="Group"
              link={`/client/${clientId}/create-group`}
            />
          </Box>
        ),
        title: 'User management',
      }}
      isPrivate
    >
      <UserSearchFilterForm
        clientId={clientId}
        filters={filters}
        page={pageCriteria}
        showFilter={showFilter}
        sort={sortCriteria}
      />
      <Form
        initialValues={{
          groupId: filters.values.groupId || '',
          name: filters.values.name || '',
          roleId: filters.values.roleId || '',
          status: filters.values.status || '',
        }}
        onSubmit={noop}
        render={({ values, handleSubmit }) => (
          <Box
            as="form"
            onSubmit={handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
            }}
          >
            <AutoSave
              save={() => {
                return navigate(
                  setDashboardURL({
                    filters,
                    filterValues: values,
                    page: {
                      offset: 0,
                      pageSize: pageCriteria.pageSize,
                    },
                    pathname: location.pathname,
                    sort: sortCriteria,
                  }),
                  { replace: true }
                );
              }}
            />

            {groupName ? (
              <Box
                bg="white"
                fontSize={4}
                fontWeight={600}
                p={5}
                sx={{
                  borderBottom: '1px solid',
                  borderColor: 'gray2',
                }}
              >
                {groupName}
              </Box>
            ) : null}
            <Card m={0} p={0} sx={{ borderRadius: 0 }}>
              <Table
                header={[
                  'Name',
                  'Role',
                  'Groups',
                  'Status',
                  'Trading Partners',
                  '',
                ]}
                isLoading={loading}
                rows={allUser.map(renderUserRow)}
              />
              <TooltipBox id="tooltip" />
              <TagTooltipBox />
            </Card>
            <DashboardPaginator
              filters={{
                page: pageCriteria.offset,
              }}
              goToNextPage={() =>
                navigate(
                  setDashboardURL({
                    filters,
                    filterValues: filters.values,
                    page: {
                      offset: pageCriteria.offset + pageCriteria.pageSize,
                      pageSize: pageCriteria.pageSize,
                    },
                    pathname: location.pathname,
                    sort: sortCriteria,
                  }),
                  { replace: true }
                )
              }
              goToPreviousPage={() =>
                navigate(
                  setDashboardURL({
                    filters,
                    filterValues: filters.values,
                    page: {
                      offset: pageCriteria.offset - pageCriteria.pageSize,
                      pageSize: pageCriteria.pageSize,
                    },
                    pathname: location.pathname,
                    sort: sortCriteria,
                  }),
                  { replace: true }
                )
              }
              isClientSidePagination
              pageTotal={allUser.length}
            />
          </Box>
        )}
      />
      {isDeleteTriggered && (
        <Modal
          closeText="Cancel"
          isOpen={isDeleteTriggered}
          onClose={() => {
            setActionConfirmation(null);
          }}
          onSubmit={() => {
            handleUserAction(
              showActionConfirmation.user,
              showActionConfirmation.action
            );
          }}
          style={{
            content: {
              maxWidth: theme.sizes.maxWidths.form,
            },
          }}
          submitText="Delete"
          submitting={actionInProgress}
          title="Delete user"
        >
          Are sure you want to delete the user{' '}
          <strong>{setDisplayName(showActionConfirmation.user)}</strong>?
        </Modal>
      )}
      {isStatusToggleTriggered && (
        <Modal
          closeText="Cancel"
          isOpen={isStatusToggleTriggered}
          onClose={() => {
            setActionConfirmation(null);
          }}
          onSubmit={() => {
            handleUserAction(
              showActionConfirmation.user,
              showActionConfirmation.action
            );
          }}
          style={{
            content: {
              maxWidth: theme.sizes.maxWidths.form,
            },
          }}
          submitText={
            showActionConfirmation.action === USER_ACTIONS.ACTIVE
              ? 'Disable'
              : 'Enable'
          }
          submitting={actionInProgress}
          title={
            showActionConfirmation.action === USER_ACTIONS.ACTIVE
              ? 'Disable user'
              : 'Enable User'
          }
        >
          Are sure you want to{' '}
          {showActionConfirmation.action === USER_ACTIONS.ACTIVE
            ? 'disable'
            : 'enable'}{' '}
          the user:{' '}
          <strong>{setDisplayName(showActionConfirmation.user)}</strong>?
        </Modal>
      )}
    </Route>
  );
};

ClientUserDashboardPage.propTypes = {
  clientId: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
};

export default ClientUserDashboardPage;
