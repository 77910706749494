import Box from '@shoreag/base/Box';
import PropTypes from 'prop-types';
import React from 'react';

const ColumnsGridCard = ({ children, label, labelSx, sx }) => {
  return (
    <>
      {label && (
        <Box
          as="h2"
          sx={{
            color: 'grays.4',
            fontSize: 3,
            fontWeight: 400,
            mb: 4,
            ...labelSx,
          }}
        >
          {label}
        </Box>
      )}
      <Box
        sx={{
          breakInside: 'avoid',
          columnCount: children.length > 1 ? 3 : 1,
          gap: 2,
          ...sx,
        }}
      >
        {children}
      </Box>
    </>
  );
};

ColumnsGridCard.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  labelSx: PropTypes.shape({}),
  sx: PropTypes.shape({}),
};

ColumnsGridCard.defaultProps = {
  children: null,
  label: '',
  labelSx: {},
  sx: {},
};

export default ColumnsGridCard;
