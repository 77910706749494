import PropTypes from 'prop-types';
import Button from '@shoreag/base/Button';
import React from 'react';

const RemoveButton = ({ sx, ...rest }) => (
  <Button simple sx={{ color: 'error', fontSize: 2, ml: 5, ...sx }} {...rest} />
);

RemoveButton.propTypes = {
  sx: PropTypes.shape({}),
};

RemoveButton.defaultProps = {
  sx: {},
};

export default RemoveButton;
